import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import { Alert, AlertTitle, AppBar, Button, Card, Container, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { HomePage } from './Pages/Home';
import StepToRun from './Pages/Run/RunIndex';
import SettingsPage from './Pages/Settings';
import QRCard from './components/qrCard';
import UserList from './components/userlistCard';
import { useEffect, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SettingsIcon from '@mui/icons-material/Settings';
import Scanner from './components/qrScanner';
import { parseURL } from './utils';
import { isNumberObject } from 'util/types';
import { AccessibleForward, Padding } from '@mui/icons-material';
import { V2Page } from './Pages/v2';
import { render } from '@testing-library/react';
import { QrPagev2 } from './Pages/qrpagev2';
import LoginBoxv2 from './components/loginCardv2';
import Settingsv2Page from './Pages/Settingsv2';

const QrPage = () => {
    const sendQR = () => eval(`scanQR("${localStorage.getItem("customHost")}")`);
    return (
        <Container>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 10 }}>
                <Grid item xs={4} sm={4} md={10}>
                    <h3>NOTE: 试试快速提交二维码</h3>
                    <Typography variant="body2" color="text.secondary">
                        <b>仅限今日校园内打开有效</b>
                        <br />
                        打开<b>今日校园</b>，在今日校园里的对话窗口，找到任意一个联系人（自己也行），发送链接：https://r.zrlnmsl.top，软件内打开后可以调用今日校园原生API扫描，获得媲美今日校园的扫码体验
                    </Typography>

                    <Button onClick={sendQR} variant='contained'>试试更好的二维码扫描</Button>
                </Grid>
                <Grid item xs={2} sm={4} md={4} style={{ maxWidth: "100%" }}>
                    <Scanner />
                </Grid>
                <Grid item xs={2} sm={4} md={6} style={{ maxWidth: "100%" }}>
                    <UserList maxHeight={500} />
                </Grid>
            </Grid>
        </Container>
    );
}

const AboutPage = () => {
    return (
        <Stack spacing={3}>
            <Typography variant="h3" component="div" align="center">
                如何使用 RunTech
            </Typography>
            {/* link */}
            <Typography variant="h5" component="div" align="center">
                <a href="https://note.ms/runtech">RunTech 使用说明</a>
            </Typography>
        </Stack>
    )
};

export default function MainNavigation() {
    const [value, setValue] = React.useState(0);
    const [selectedPage, setSelectedPage] = useState('home');
    const handlePageChange = (newValue: string) => {
        setSelectedPage(newValue);
    };
    const [thanksCount, setThanksCount] = useState("0");

    const renderPage = () => {
        // return [<V2Page />, <HomePage />, <StepToRun />, <QrPage />, <SettingsPage />,][value];
        return [<V2Page />, <LoginBoxv2 />, <QrPagev2 />, <Settingsv2Page />][value];
    }

    const renderBanner = () => {
        if (value == 0) {
            return (
                <Alert severity="success" sx={{ mt: -5, mb: 5 }}>
                    <AlertTitle>有缘再见</AlertTitle>
                    忽驰骛以追逐兮，非余心之所急。
                    <br/>
                    老冉冉其将至兮，恐修名之不立。
                </Alert>
            )
        }
    }

    useEffect(() => {
        fetch(parseURL('/api/v1/getthanks'))
            .then(res => res.ok ? res.text() : '0')
            .then(res => setThanksCount(res.substring(0, Math.min(10, res.length))))
            .catch(err => {
                console.error(err);
                setThanksCount('0');
            })
    }, [])

    const giveThanks = () => {
        fetch(parseURL('/api/v1/thanks'))
            .then(res => res.ok ? res.text() : '0')
            .then(res => setThanksCount(res.substring(0, Math.min(10, res.length))))
            .catch(err => {
                console.error(err);
                setThanksCount('0');
            })
    }

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        RunTech 2.1 Beta
                    </Typography>

                    {/* <Typography variant="body2" component="div" paddingRight={1}>
                        已收到 {thanksCount} 次感谢
                    </Typography>
                    <Button color="inherit" onClick={giveThanks} variant={'outlined'}>点击感谢</Button> */}
                </Toolbar>
            </AppBar >
            <Box component="main" style={{
                paddingTop: 100,
                paddingBottom: 100,
            }}>
                {renderBanner()}
                {renderPage()}
            </Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 99 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="主页" icon={<AccessibleForward />} />
                    {/* <BottomNavigationAction label="旧版" icon={<HomeIcon />} /> */}
                    <BottomNavigationAction label="我要润" icon={<DirectionsRunIcon />} />
                    <BottomNavigationAction label="我是爹" icon={<CropFreeIcon />} />
                    <BottomNavigationAction label="设置" icon={<SettingsIcon />} />
                </BottomNavigation>
            </Paper>
        </>
    );
}
