import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, Container, Grid, ImageList, Paper, Stack, Typography } from "@mui/material"
import UserListv2 from "../components/userlistv2";
import LoginBoxv2 from "../components/loginCardv2";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Padding } from "@mui/icons-material";
import Scanner from "../components/qrScanner";
import QRCard from "../components/qrCard";

export const V2Page = () => {
    const sendQR = () => eval(`scanQR("${localStorage.getItem("customHost")}")`);
    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                <Typography variant="h3" component="h3" align="center" padding={1}>
                    <Badge badgeContent="Beta" color="primary">
                        RunTech 2.1
                    </Badge>
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" padding={1}>
                    RunTech 已重构
                </Typography>

                <h3>
                    <Badge badgeContent="仅校内" color="warning">
                        快速获取二维码
                    </Badge>
                </h3>

                <QRCard />

                <h3>快速提交二维码</h3>

                <Typography variant="body2" color="text.secondary">
                    <b>仅限今日校园内打开有效</b>
                    <br />
                    打开<b>今日校园</b>，在今日校园内点击下方扫描二维码，即可调用今日校园原生扫码快速提交，获得更快的扫码体验。
                </Typography>

                <Button variant="contained" onClick={() => window.open("https://www.campushoy.com/CampusNext/?action=openApp&openUrl=https%3A%2F%2Fr.zrlnmsl.top#/cpdaily")}>在 今日校园 中打开 RunTech</Button>

                <Button variant="outlined" onClick={sendQR}>扫描二维码</Button>

                <h3>网页端实时扫描</h3>
                <Typography variant="body2" color="text.secondary">
                    请转到下方 <b>我是爹</b> 来贡献二维码
                </Typography>

                <h3>登录</h3>

                <Typography variant="body2" color="text.secondary">
                    请转到下方 <b>我要润</b>
                </Typography>

                <h3>技术细节</h3>

                <div>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>如何直接获取实时二维码</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="text.secondary">
                                点击主页的 ▶ 按钮，即可<b>免登录</b>获取实时二维码
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>如何无人值守扫码</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="text.secondary">
                                点击底部的 我要润，通过账号密码登录后， RunTech 会记住你的 Cookies 和登录信息。
                                <br />
                                在每次有新的爹提供二维码时，服务器将<b>自动</b>模拟扫码，您无需再次操作。
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>如何贡献二维码</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" color="text.secondary">
                                <b>方法一：</b>打开 我是爹，并<b>允许 RunTech 访问您的摄像头</b>
                                <br />
                                点击扫描卡片左下角的设置按钮可以切换摄像头
                                <br />
                                如果弹出无法访问摄像头的提示，通常是权限不足导致的，请给予权限后<b>刷新</b>或者重新切换页面到 我是爹
                                <br />
                                <br />
                                <b>方法二：</b> 点击主页的 打开今日校园 按钮，在今日校园内点击 扫描二维码 按钮即可调用今日校园原生扫码快速提交，获得更快的扫码体验。
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            RunTech 2.1 如何保存你的密码
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: "GrayText", fontSize: "0.9em", lineHeight: "1.5em" }}>
                            RunTech 使用全程 HTTPS 和源代码开源的无服务器函数将您的密码加密传送至数据库
                            <img
                                src="https://mermaid.ink/img/pako:eNqNkt1OAjEQhV-l6a2gAcQLYkggq4EIcUNBE3eNGbaFbdxtm_64QcK727IoaIzxbjoz53wn6WxxJinDPbzWoHI0maUiFaqiyb3may6gQDEYU0lNn1GziUbzeUx80UeqaiVfo1TwEwXJcikLNI5-SDhtJb4ZCMYta-AgHhOm35gOFq2wP426aAQmP0jayWKv8bwwnZEBUstXdCOyc3SGhmDY1eUhUPs0EBP0O8jZ_EhqB69avJhNEAHBLX8Hy6W4XuqL_mnoTkJePjP8X6eqoIsfo1-yRGAhGoYcneBHmE9VMGPQrRNZ8DqQu8kd2-y5f-2pqps8QOFYTcINXDJdAqf-V7epQCjFNmclS3HPl5StwBU2xanY-VVwVpKNyHDPasca2CkKlkUcfNIS91ZQGN9VIJ6kPL4Z5VbqaX05-wPafQB1aMJk?type=png)](https://mermaid.live/edit#pako:eNqNkt1OAjEQhV-l6a2gAcQLYkggq4EIcUNBE3eNGbaFbdxtm_64QcK727IoaIzxbjoz53wn6WxxJinDPbzWoHI0maUiFaqiyb3may6gQDEYU0lNn1GziUbzeUx80UeqaiVfo1TwEwXJcikLNI5-SDhtJb4ZCMYta-AgHhOm35gOFq2wP426aAQmP0jayWKv8bwwnZEBUstXdCOyc3SGhmDY1eUhUPs0EBP0O8jZ_EhqB69avJhNEAHBLX8Hy6W4XuqL_mnoTkJePjP8X6eqoIsfo1-yRGAhGoYcneBHmE9VMGPQrRNZ8DqQu8kd2-y5f-2pqps8QOFYTcINXDJdAqf-V7epQCjFNmclS3HPl5StwBU2xanY-VVwVpKNyHDPasca2CkKlkUcfNIS91ZQGN9VIJ6kPL4Z5VbqaX05-wPafQB1aMJk"
                                style={{ maxWidth: '100%' }}
                            />
                            您的密码将会被 RSA 公钥加密后传送至数据库，并将您的 ID 在 Hash 过后以键值对的方式加密存储在数据库内
                            <br />
                            在更新密码时，您的密码将会在无服务器函数内被 RSA 私钥解密，并且与一同发来的 EncryptSalt 进行 AES 加密，并将加密过后的密码返回给 API 服务器
                            <br />
                            <b>这个私钥是私密且在部署时随机生成于敏感环境变量中</b>，根据 Vercel 的 Sensitive Environment Variables 限制，这个私钥将被加密存储且不可读取，这意味着您的密码几乎没有可能被解密
                            <img
                                src="https://mermaid.ink/img/pako:eNptUl1LwzAU_Sslzw6xiA9FBhtVNpw41s0HG5G75m4NtknIh2OM_XeTdJ0TfWlyzz3nnntoDqSSDElGthpUncwWVFBh3LorR_NpgfoLNRWc3ZQTMDWy-7W-Hk7zd8-DxpYPotJ7ZSNaeMDjqHZpj5_4czBmJ3VXzOSWi8AT7Leds_WFXzIYJGMweHe7WsyicLJczgsPDxPO0rL4WJ3XCNy-G_oBS__bTaryRXPvD42Hz3vFThiyKEaJ0p9JjlEazXyeMshjlktJdAmqk1HP7s_0b8YcLOTjkC8Ku7gNGhOXfHSislyKOOAJ91T4TyxeoXEYb1LFqeSKtKhb4Mz_vQMVSUKJrbFFSjJ_ZbgB11hKqDh6Kjgri72oSGa1wyviFAOLOQe_VUuyDTTGowrEm5Q_NTJupX7uXkh8KMdvy4TAZQ?type=png)](https://mermaid.live/edit#pako:eNptUl1LwzAU_Sslzw6xiA9FBhtVNpw41s0HG5G75m4NtknIh2OM_XeTdJ0TfWlyzz3nnntoDqSSDElGthpUncwWVFBh3LorR_NpgfoLNRWc3ZQTMDWy-7W-Hk7zd8-DxpYPotJ7ZSNaeMDjqHZpj5_4czBmJ3VXzOSWi8AT7Leds_WFXzIYJGMweHe7WsyicLJczgsPDxPO0rL4WJ3XCNy-G_oBS__bTaryRXPvD42Hz3vFThiyKEaJ0p9JjlEazXyeMshjlktJdAmqk1HP7s_0b8YcLOTjkC8Ku7gNGhOXfHSislyKOOAJ91T4TyxeoXEYb1LFqeSKtKhb4Mz_vQMVSUKJrbFFSjJ_ZbgB11hKqDh6Kjgri72oSGa1wyviFAOLOQe_VUuyDTTGowrEm5Q_NTJupX7uXkh8KMdvy4TAZQ"
                                style={{ maxWidth: '100%' }}
                            />
                            由于该安全限制，您的密码只能通过 <b>覆盖</b> 的方式重新设置，即点击登录按钮
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            RunTech 的原理是什么
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: "GrayText" }}>
                            RunTech 通过 <b>模拟</b> 扫码的方式，代替真实的扫码，所有接口来自官方接口，确保可用性
                            <br />
                            每个 API 服务器将会维护各用户的 Cookies ，以保证用户的登录状态，在登录失效时会向 Auth 服务器发送请求以获取新的加密密码用来重新登录
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            分享 RunTech
                        </AccordionSummary>
                        <AccordionDetails sx={{ color: "GrayText" }}>
                            <Grid sx={{ flexGrow: 1 }} container justifyContent="center" spacing={2}>
                                <Grid item xs={6}>
                                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://r.zrlnmsl.top" />
                                    <Typography align="center">RunTech Web URL</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://t.me/RunTechTG" />
                                    <Typography align="center">RunTech Telegram</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <h3>用户列表</h3>

                <UserListv2 />

                <Grid alignSelf="center" padding={1}>
                    <img src="https://ipacel.cc/+/MoeCounter2/?name=runtech" alt="RunTech 访问量" style={{ maxWidth: "100%" }} />
                </Grid>
            </Stack>
        </Container>
    )
}